<template>
  <div class="Calculator">
    <div class="Calculator_h">
      <div class="Calculator_bac"></div>
      <div class="Calculator_title">
        <h3>薪税计算器</h3>
        <p>智能分配，为您节税</p>
      </div>
      <div class="Calculator_box">
        <div class="Calculator_content">
          <div class="Calculator_top">
            <p class="title_p">社保缴纳</p>
            <div class="Calculator_top_money">
              <span class="unit_lf">社保缴纳基数</span>
              <van-field
                v-model="number"
                type="number"
                input-align="right"
                placeholder="请输入"
                @blur="onblur"
              />
              <span class="unit_ri">元/月</span>
            </div>
          </div>
          <div class="Proportion_box">
            <div class="proportion_radio">
              <van-button
                :plain="active !== 1"
                type="primary"
                size="small"
                @click="lowestClick(1)"
                >最低标准</van-button
              >
              <van-button
                :plain="active !== 2"
                type="info"
                size="small"
                @click="lowestClick(2)"
                >全额标准</van-button
              >
              <van-button
                :plain="active !== 3"
                type="danger"
                size="small"
                @click="lowestClick(3)"
                >不缴纳</van-button
              >
            </div>
          </div>
          <div class="payment_ratio">
            <table class="payment_ratio_table">
              <thead>
                <tr>
                  <th>缴纳基数</th>
                  <th>单位缴纳</th>
                  <th>个人缴纳</th>
                  <th>合计</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in tableList" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ Math.round(item.unit * 100) / 100 }}</td>
                  <td>
                    {{
                      item.personal
                        ? Math.round(item.personal * 100) / 100
                        : "--"
                    }}
                  </td>
                  <td class="blue">
                    {{ Math.round((item.unit + item.personal) * 100) / 100 }}
                  </td>
                </tr>
                <tr>
                  <td>合计</td>
                  <td>{{ Math.round(totalUnit * 100) / 100 }}</td>
                  <td>{{ Math.round(totalPersonal * 100) / 100 }}</td>
                  <td class="blue">
                    {{ Math.round((totalUnit + totalPersonal) * 100) / 100 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom_but">
          <van-button type="info" @click="$router.go(-1)">确认</van-button>
        </div>
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import { Field, Button, Toast } from "vant";
import { findAllTSocialSecurity } from "@/api/Calculator";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Toast.name]: Toast,
    copyrightIp,
  },

  data() {
    return {
      number: null,
      infoNum: null, // 税前工资
      data: {},
      lowestVal: "", //最低标准
      highestVal: "", //最高标准
      active: null,
      tableList: [
        {
          name: "养老",
          unit: "",
          personal: "",
        },
        {
          name: "医保",
          unit: "",
          personal: "",
        },
        {
          name: "失业",
          unit: "",
          personal: "",
        },
        {
          name: "工伤",
          unit: "",
          personal: "",
        },
      ],
      totalUnit: 0, // 公司
      totalPersonal: 0, // 个人
    };
  },
  created() {
    this.infoNum = this.$route.query.number || 0;
    this.getFindAllTSocialSecurity();
  },
  mounted() {},
  beforeDestroy() {
    sessionStorage.setItem("socialSecurity", this.totalPersonal);
    sessionStorage.setItem("socialSecurityUnit", this.totalUnit);
  },
  methods: {
    // 输入框失焦事件
    onblur() {
      this.isInterval();
      this.tableConvert();
      this.active = "";
    },
    // 判断是否超出区间
    isInterval(num = this.number) {
      num = Number(num);
      let min = Number(this.lowestVal);
      let max = Number(this.highestVal);

      if (num < min) {
        this.number = min;
        this.active = 1;
        Toast(`本市最低缴纳基数${this.number}`);
      } else if (this.infoNum && num > this.infoNum && num < max) {
        this.number = this.infoNum;
        this.active = 2;
        Toast("缴纳基数不能大于税前收入哦!");
      } else if (num > max) {
        this.number = max;
        this.active = 2;
        Toast(`本市最高缴纳基数${this.number}`);
      } else {
        this.number = num;
      }
    },
    // 表格变换
    tableConvert() {
      this.tableList[0].unit = this.number * this.data.companyAged;
      this.tableList[0].personal = this.number * this.data.userAged;

      this.tableList[1].unit = this.number * this.data.companyMedicalInsurance;
      // this.tableList[1].personal = this.number * Nu this.data.userMedicalInsurance;
      this.tableList[1].personal =
        this.number * this.data.userMedicalInsurance +
        Number(this.data.userMedicalInsuranceValue);

      this.tableList[2].unit = this.number * this.data.companyUnemployment;
      this.tableList[2].personal = this.number * this.data.userUnemployment;

      this.tableList[3].unit = this.number * this.data.companyJobInjury;

      this.totalUnit = 0;
      this.totalPersonal = 0;
      this.tableList.forEach((item) => {
        if (Number(item.unit)) {
          // this.totalUnit = (this.totalUnit * 1000 + item.unit * 1000) / 1000;
          this.totalUnit = Math.round((this.totalUnit + item.unit) * 100) / 100;
        }
        if (Number(item.personal)) {
          this.totalPersonal =
            Math.round((this.totalPersonal + item.personal) * 100) / 100;
          // (this.totalPersonal * 1000 + item.personal * 1000) / 1000;
        }
      });
    },
    // 选择标准
    lowestClick(type) {
      this.active = type;
      switch (type) {
        case 1:
          this.number = this.lowestVal;
          this.tableConvert();
          break;
        case 2:
          if (this.infoNum > this.highestVal) {
            this.number = this.highestVal;
            Toast(`本市最高缴纳基数${this.number}`);
          } else if (this.infoNum < this.lowestVal) {
            this.number = this.lowestVal;
            this.active = 1;
            Toast(`本市最低缴纳基数${this.number}`);
          } else {
            this.number = this.infoNum;
          }
          this.tableConvert();
          break;
        case 3:
          this.totalPersonal = 0;
          this.totalUnit = 0;
          this.$router.push("/calculator/moon");
          break;
      }
    },
    // 获取社保缴纳比例
    getFindAllTSocialSecurity() {
      findAllTSocialSecurity().then((res) => {
        this.data = res.data.data[0];
        this.lowestVal = Number(this.data.baseLowerLimit);
        this.highestVal = Number(this.data.baseUpperLimit);
        this.isInterval(this.infoNum);
        this.tableConvert();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.blue {
  color: #3d8df7;
}
.Calculator {
  width: 100%;
  height: 100%;
  position: relative;
  .Calculator_h {
    min-height: calc(100% - 44px);
  }
  // padding-bottom: 70px;
  .Calculator_bac {
    width: 100%;
    height: 250px;
    background-image: url("../../../assets/img/Utilities1_Moon_01.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .Calculator_title {
    padding: 10px 10px 0;
    color: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  .Calculator_box {
    width: 100%;
    padding: 10px;

    .Calculator_content {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
    }
    .bottom_but {
      background: #fff;
      padding: 10px;
      .van-button {
        width: 100%;
        border-radius: 6px;
      }
    }
  }
}
.Calculator_top {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 10px;
  font-weight: bold;
  .title_p {
    font-size: 17px;
    text-align: center;
  }
  .Calculator_top_money {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .unit_lf {
      // font-size: 35px;
      font-size: 16px;
      min-width: 100px;
      color: #081222;
      // font-weight: 100;
    }
    .unit_ri {
      min-width: 38px;
      color: #999999;
    }
    ::v-deep .van-cell {
      padding-left: 3px;
      .van-cell__value {
        font-size: 25px;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
        font-size: 25px;
        font-weight: 100;
        line-height: 80px;
      }
    }
  }
}
.Proportion_box {
  padding-bottom: 15px;
  .proportion_radio {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .van-button {
      min-width: 66px;
      border-radius: 5px;
    }
  }
}
.payment_ratio {
  width: 100%;
  padding-bottom: 28px;
  .payment_ratio_table {
    width: 100%;
    thead {
      font-size: 12px;
      background: #3d8df7;
      color: #fff;
      position: relative;
      &::before {
        content: "";
        width: 10px;
        height: 25px;
        background: #3d8df7;
        position: absolute;
        top: 0;
        left: -10px;
      }
      &::after {
        content: "";
        width: 10px;
        height: 25px;
        background: #3d8df7;
        position: absolute;
        top: 0;
        right: -10px;
      }
      tr {
        line-height: 25px;
        th {
          text-align: center;
        }
      }
    }
    tbody {
      font-size: 14px;
      tr {
        border-bottom: 1px solid #efecec;

        td {
          width: 25%;
          line-height: 35px;
          text-align: center;
        }
      }
      .total {
        color: #327ff9;
      }
    }
  }
}
</style>
